import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Row } from './helpers';
import { Dropdown, Button } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "dropdown"
    }}>{`Dropdown`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Dropdown from '@mfrm/mfcl/Dropdown'
`}</code></pre>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Dropdown} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Component initialState={{ value: { name: \'1\', value: \'1\' } }}>\n  {({ setState, state }) => (\n    <Row>\n      <Dropdown\n        onChange={option => setState({ value: option })}\n        options={[\n          { name: \'1\', value: \'1\' },\n          { name: \'2\', value: \'2\' },\n          { name: \'3\', value: \'3\' },\n          { name: \'4\', value: \'4\' },\n          { name: \'5\', value: \'5\', disabled: true },\n          { name: \'6\', value: \'6\' },\n        ]}\n        value={state.value}\n      />\n    </Row>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Row,
      Dropdown,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        value: {
          name: '1',
          value: '1'
        }
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <Row mdxType="Row">
        <Dropdown onChange={option => setState({
            value: option
          })} options={[{
            name: '1',
            value: '1'
          }, {
            name: '2',
            value: '2'
          }, {
            name: '3',
            value: '3'
          }, {
            name: '4',
            value: '4'
          }, {
            name: '5',
            value: '5',
            disabled: true
          }, {
            name: '6',
            value: '6'
          }]} value={state.value} mdxType="Dropdown" />
      </Row>}
  </Component>
    </Playground>
    <br></br>
    <h2 {...{
      "id": "customizable-children"
    }}>{`Customizable Children`}</h2>
    <Playground __position={3} __code={'<Component initialState={{ value: { name: \'Set as Preferred\', value: 0 } }}>\n  {({ setState, state }) => (\n    <Row>\n      <Dropdown\n        onChange={option => setState({ value: option })}\n        options={[\n          { name: \'Set as Preferred\', value: 0, disabled: true },\n          { name: \'Remove\', value: 1 },\n          { name: \'Settings\', value: 2 },\n        ]}\n        position=\"bottom-left\"\n        value={state.value}\n        offset=\"40px\"\n      >\n        <Button btnType=\"primary\" size=\"lg\">\n          ...\n        </Button>\n      </Dropdown>\n    </Row>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Row,
      Dropdown,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        value: {
          name: 'Set as Preferred',
          value: 0
        }
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <Row mdxType="Row">
        <Dropdown onChange={option => setState({
            value: option
          })} options={[{
            name: 'Set as Preferred',
            value: 0,
            disabled: true
          }, {
            name: 'Remove',
            value: 1
          }, {
            name: 'Settings',
            value: 2
          }]} position="bottom-left" value={state.value} offset="40px" mdxType="Dropdown">
          <Button btnType="primary" size="lg" mdxType="Button">
            ...
          </Button>
        </Dropdown>
      </Row>}
  </Component>
    </Playground>
    <br></br>
    <h2 {...{
      "id": "controlled-dropdown"
    }}>{`Controlled Dropdown`}</h2>
    <Playground __position={5} __code={'<Component initialState={{ value: { name: \'1\', value: \'1\' }, isOpen: false }}>\n  {({ setState, state }) => (\n    <Row>\n      <Dropdown\n        open={state.isOpen}\n        onChange={option => setState({ value: option })}\n        options={[\n          { name: \'1\', value: \'1\' },\n          { name: \'2\', value: \'2\' },\n          { name: \'3\', value: \'3\' },\n          { name: \'4\', value: \'4\' },\n          { name: \'5\', value: \'5\', disabled: true },\n          { name: \'6\', value: \'6\' },\n        ]}\n        value={state.value}\n      />\n      <Button\n        btnType=\"primary\"\n        onClick={() => setState({ isOpen: !state.isOpen })}\n        size=\"lg\"\n      >\n        {state.isOpen ? \'Close\' : \'Open\'} me\n      </Button>\n    </Row>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Row,
      Dropdown,
      Button,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        value: {
          name: '1',
          value: '1'
        },
        isOpen: false
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <Row mdxType="Row">
        <Dropdown open={state.isOpen} onChange={option => setState({
            value: option
          })} options={[{
            name: '1',
            value: '1'
          }, {
            name: '2',
            value: '2'
          }, {
            name: '3',
            value: '3'
          }, {
            name: '4',
            value: '4'
          }, {
            name: '5',
            value: '5',
            disabled: true
          }, {
            name: '6',
            value: '6'
          }]} value={state.value} mdxType="Dropdown" />
        <Button btnType="primary" onClick={() => setState({
            isOpen: !state.isOpen
          })} size="lg" mdxType="Button">
          {state.isOpen ? 'Close' : 'Open'} me
        </Button>
      </Row>}
  </Component>
    </Playground>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      